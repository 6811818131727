
import './router/interceptor'
import Routes from './router/router'

function App() {

  return (

    <Routes />
  );
}

export default App;
